import { useParams } from 'react-router-dom'
import { AppLayout } from '@layouts'
import { Toaster } from 'react-hot-toast'
import GalleryPage from '../pages/gallery.page'

const GalleryContainer = () => {
  const { solutionId } = useParams()

  if (!solutionId) {
    throw new Error(
      'The solutionId is required to list all photos for one specific solution'
    )
  }

  return (
    <AppLayout>
      <GalleryPage solutionId={solutionId} />
      <Toaster />
    </AppLayout>
  )
}

export default GalleryContainer
