import { http } from '@http'

export const Api = {
  exportSolutionApplicants: async (solutionId: string | number | undefined) => {
    try {
      const response = await http.get(
        `/solution_applications/export?filter[solution_id]=${solutionId}`
      )
      return response
    } catch (err) {
      console.log(err)
    }
  },
  getCurrentUser: async () => {
    try {
      return await http.get('/me')
    } catch (err) {
      console.log(err)
    }
  },
}
