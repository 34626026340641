import { TApplication } from '../../../types'
import ApplicationOwner from './application-owner'
import ApplicationInfo from './application-info'
import ApplicationProgress from './application-progress'
import { When } from 'react-if'
import TextOverflow from '../../text-overflow'

type TProps = {
  data: TApplication
  onClick: (application: TApplication) => void
}

const Application: React.FC<TProps> = ({ data, onClick }) => {
  const handleApplicationClick = (event) => {
    if (!event.target.href) {
      onClick(data)
    }
  }

  return (
    <div
      className="border-b border-gray-200 px-6 py-7 hover:cursor-pointer hover:bg-gray-100"
      onClick={handleApplicationClick}
    >
      <div className="flex gap-10">
        <ApplicationOwner data={data} />
        <ApplicationInfo data={data} />
        <ApplicationProgress data={data} />
      </div>
      <When condition={data.message}>
        <div className="mt-6 ml-14 max-w-xl border-l-4 px-4 text-sm text-gray-500">
          <TextOverflow length={140}>{data.message}</TextOverflow>
        </div>
      </When>
    </div>
  )
}

export default Application
