import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Menu as DSMenu } from '@changex/design-system'
import styled from 'styled-components'
import { useSolutionById } from '@features/applications/hooks'

const StyledMenu = styled(DSMenu)`
  grid-area: aside;
`

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

const Menu = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleNavigationClick = (route) => {
    if (route.startsWith('http')) {
      window.location.href = route
      return null
    }
    navigate(route)
  }
  const { solutionId } = useParams()

  const { data } = useSolutionById(solutionId ?? 0)

  const getFullUrl = (path, prefix = '') => {
    if (prefix !== '') {
      return `${CHANGEX_ADDRESS}/${prefix}/${data?.slug}/${path}`
    }
    return `${CHANGEX_ADDRESS}/${data?.slug}/${path}`
  }

  return (
    <StyledMenu>
      <StyledMenu.Header>{data?.name}</StyledMenu.Header>
      <StyledMenu.Items>
        <StyledMenu.NavItem
          active={location.pathname.match(/\/solutions\/[0-9]+\/applications/)}
          route={`/solutions/${solutionId}/applications`}
          onNavigationClick={handleNavigationClick}
        >
          Applications
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/impact')}
          onNavigationClick={handleNavigationClick}
        >
          News feed
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          active={location.pathname.match(/\/solutions\/[0-9]+\/photos/)}
          route={`/solutions/${solutionId}/photos`}
          onNavigationClick={handleNavigationClick}
        >
          Photos
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/events')}
          onNavigationClick={handleNavigationClick}
        >
          Calendar
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/locations/admin')}
          onNavigationClick={handleNavigationClick}
        >
          Locations
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/memberships?role=admin', 'solutions')}
          onNavigationClick={handleNavigationClick}
        >
          Members
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/resources/admin')}
          onNavigationClick={handleNavigationClick}
        >
          Resources
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/payments/history')}
          onNavigationClick={handleNavigationClick}
        >
          Payments
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          active={location.pathname.match(
            /\/solutions\/[0-9]+\/payments\/funds/
          )}
          route={`/solutions/${solutionId}/payments/funds`}
          onNavigationClick={handleNavigationClick}
        >
          Funds
        </StyledMenu.NavItem>
        <StyledMenu.NavItem
          route={getFullUrl('/edit', 'solution_manage')}
          onNavigationClick={handleNavigationClick}
        >
          Edit solution
        </StyledMenu.NavItem>
      </StyledMenu.Items>
    </StyledMenu>
  )
}

export default Menu
