import { Fragment } from 'react'
import logo from './../../assets/logo.png'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { TUser } from '@features/applications/types'

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

const userNavigation = [
  { name: 'My account', href: `${CHANGEX_ADDRESS}/account` },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

type TProps = {
  user?: TUser
}

const Header: React.FC<TProps> = ({ user }) => {
  return (
    <div className="bg-white py-3.5 px-8 shadow-sm">
      <div className="flex justify-between">
        <a href={CHANGEX_ADDRESS} className="flex items-center">
          <img
            src={logo}
            className="flex h-7"
            alt="ChangeX logo"
            data-testid="logo"
          />
        </a>
        <div className="user">{user ? <UserMenu {...user} /> : null}</div>
      </div>
    </div>
  )
}

const UserMenu: React.FC<Partial<TUser>> = ({ avatarUrl, firstName }) => (
  <Menu as="div" className="relative ml-3">
    <div>
      <Menu.Button className="flex max-w-xs items-center rounded-full text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
        <span className="sr-only">Open user menu</span>
        <div className="flex items-center">
          {avatarUrl ? (
            <img
              className="inline-block h-8 w-8 rounded-full"
              src={avatarUrl}
              alt=""
            />
          ) : (
            <DefaultAvatar />
          )}
          <div className="ml-3">
            <p className="text-gray-700 group-hover:text-gray-900">
              {firstName}
            </p>
          </div>
          <div className="ml-0.5">
            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
          </div>
        </div>
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {userNavigation.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <a
                href={item.href}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                {item.name}
              </a>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  </Menu>
)

const DefaultAvatar = () => (
  <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
    <svg
      className="h-full w-full text-gray-300"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  </span>
)

export default Header
