type TProps = {
  children: React.ReactNode | React.ReactNode[]
  className?: string
}

const Container: React.FC<TProps> = ({ children, className = '' }) => (
  <div className={`p-16 pt-8 ${className}`}>{children}</div>
)

export default Container
