import { Icon, Text, SlideOver, Button } from '@changex/design-system'
import { useState } from 'react'

const StatusLegend = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClose = () => setIsOpen(false)

  const handleOpen = () => setIsOpen(true)

  return (
    <div className="flex items-center">
      <div onClick={handleOpen} className="mr-4">
        <Button weight="tertiary" className="border-0 font-light shadow-none">
          <Icon className="mr-2.5" icon="QuestionMarkCircle" size="sm" />
          Status legend
        </Button>
      </div>
      <SlideOver
        title="Status legend"
        children={
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col">
              <Text>Total applications</Text>
              <Text variant="light">
                Those in the preallocated, allocated, succeeded, approved paid
                seed, impact, paid impact, failed, failed impact, rejected,
                refunded state.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>In challenge</Text>
              <Text variant="light">
                Applications within the 30 day challenge
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Preallocated</Text>
              <Text variant="light">
                Applications in the fund that have not yet performed the
                necessary actions to move to the allocated stage.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Allocated</Text>
              <Text variant="light">
                Applications that have performed the required actions to be
                allocated funding.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Successful</Text>
              <Text variant="light">
                Applications that have successfully completed the 30 Day
                Challenge.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Approved</Text>
              <Text variant="light">
                Applications that have been reviewed by ChangeX Team after
                completing the 30 Day Challenge and are ready to be paid their
                seed funding.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Paid seed</Text>
              <Text variant="light">
                Applications that have been paid their seed funding, 70% of
                total funding.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Impact</Text>
              <Text variant="light">
                Applications that have reported on their impact and are to be
                reviewed by CX before the final 30% of funding is released.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Paid impact</Text>
              <Text variant="light">
                Applications that have reported impact and received 100% of
                their funding.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Failed impact</Text>
              <Text variant="light">
                Applications that received their seed funding but have not
                received their impact funding due to not fulfilling criteria.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Rejected</Text>
              <Text variant="light">
                Applications rejected and no longer allocated funding.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Refunded</Text>
              <Text variant="light">
                Applications by those unable to complete their projects and who
                have refunded ChangeX with the funds that they received.
              </Text>
            </div>
            <div className="flex flex-col">
              <Text>Not funded</Text>
              <Text variant="light">
                Applications that are not allocated funding.
              </Text>
            </div>
          </div>
        }
        open={isOpen}
        onClose={handleClose}
      />
    </div>
  )
}

export default StatusLegend
