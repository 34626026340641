import { Divider, Tabs } from '@changex/design-system'
import { TApplication } from '../../types'
import ApplicationDetailsCall from './application-details-call'
import ApplicationDetailsInfo from './application-details-info'
import ApplicationDetailsMotivation from './application-details-motivation'
import ApplicationDetailsNotes from './application-details-notes'
import ApplicationDetailsSteps from './application-details-steps'
import ApplicationDetailsAdditionalData from './application-details-additional-data'

type TProps = {
  data?: TApplication | null
  onChangexNotes: (note: string, applicationId: number) => void
  onTeamNotes: (note: string, applicationId: number) => void
}

const ApplicationDetailsBody: React.FC<TProps> = ({
  data,
  onChangexNotes,
  onTeamNotes,
}) => (
  <>
    <Tabs
      tabs={[
        {
          title: 'Overview',
          content: (
            <>
              <ApplicationDetailsInfo data={data} />
              <Divider />
              <ApplicationDetailsSteps data={data} />
              <Divider />
              <ApplicationDetailsMotivation data={data} />
              <ApplicationDetailsNotes
                data={data}
                onChangexNotes={onChangexNotes}
                onTeamNotes={onTeamNotes}
              />
              <ApplicationDetailsCall data={data} />
              <ApplicationDetailsAdditionalData data={data?.additionalData} />
            </>
          ),
        },
      ]}
    />
  </>
)

export default ApplicationDetailsBody
