import { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface PageProps {}

const Page: FC<PageProps> = () => {
  const { releaseSiImpactDashboard } = useFlags()
  return (
    <>
      <h1>Hello world</h1>
      {releaseSiImpactDashboard ? (
        <h2>LaunchDarkly flag enabled</h2>
      ) : (
        <h2>LaunchDarkly flag disabled</h2>
      )}
    </>
  )
}

export default Page
