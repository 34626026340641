const Skeleton = () => (
  <div
    className="boder-gray-200 flex animate-pulse justify-between rounded border border-t-0 p-6 first:border-t"
    data-testid="skeleton"
  >
    <div className="flex">
      <div className="h-11 w-11 rounded-full bg-gray-200" />
      <div className="ml-4">
        <div className="h-5 w-[150px] rounded bg-gray-200" />
        <div className="mt-2 h-5 w-[200px] rounded bg-gray-200" />
        <div className="mt-6 h-5 w-[220px] rounded bg-gray-200" />
        <div className="mt-2 h-5 w-[175px] rounded bg-gray-200" />
      </div>
    </div>
    <div>
      <div className="flex">
        <div className="h-5 w-[50px] rounded bg-gray-200" />
        <div className="ml-2 h-5 w-[100px] rounded bg-gray-200" />
      </div>
      <div className="mt-3 flex">
        <div className="h-5 w-[90px] rounded bg-gray-200" />
        <div className="ml-2 h-5 w-[150px] rounded bg-gray-200" />
      </div>
      <div className="mt-3 flex">
        <div className="h-5 w-[70px] rounded bg-gray-200" />
        <div className="ml-2 h-5 w-[120px] rounded bg-gray-200" />
      </div>
    </div>
    <div>
      <div className="flex">
        <div className="h-5 w-[50px] rounded bg-gray-200" />
        <div className="ml-6 h-5 w-[150px] rounded bg-gray-200" />
      </div>
      <div className="mt-4 h-5 w-full rounded bg-gray-200" />
      <div className="mt-4 h-5 w-[85px] rounded bg-gray-200" />
    </div>
  </div>
)

export default Skeleton
