import {
  ImagesSlideGallery,
  Pagination,
  Skeleton,
  ViewSwitcher,
} from '@changex/design-system'
import dayjs from 'dayjs'
import { TSolutionPhoto } from '../types'
import GalleryFilters from '../components/gallery-filters'
import GalleryLegend from '../components/gallery-legend'
import { utils } from '../utils'
import { useGalleryPage } from '@features/applications/hooks'
import PhotoCard from '../components/photo-card'

type TProps = {
  solutionId: string
}

const GalleryPage: React.FC<TProps> = ({ solutionId }) => {
  const {
    galleryFilters,
    setGridViewActive,
    isFetching,
    totalPhotosCount,
    photosData,
    handleImageClick,
    gridViewActive,
    pagination,
    galleryImages,
  } = useGalleryPage(solutionId)

  return (
    <div className="-m-16 -mt-8">
      <div className="bg-copper-50 z-30 mb-4 flex items-center justify-between px-4 py-2.5">
        <GalleryFilters
          filters={galleryFilters.filters}
          onFilter={galleryFilters.handleFilter}
          onReset={galleryFilters.handleResetFilters}
          funds={galleryFilters.formattedFunds}
        />

        <ViewSwitcher setGridView={setGridViewActive} />
      </div>

      {isFetching ? (
        <div className="px-4" data-testid="loading-skeleton">
          <div className="flex justify-between">
            <Skeleton h="24px" w="100px" />
            <Skeleton h="24px" w="70px" />
          </div>
          <div className="my-5 grid grid-cols-4 gap-[14px]">
            {[...Array(16)].map((e, i) => (
              <Skeleton h="250px" key={i} className="rounrounded-[10px]" />
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between px-4 py-0">
            <div className="text-base font-normal text-gray-700">
              {totalPhotosCount} photos
            </div>
            <GalleryLegend />
          </div>

          <div className="mb-5 grid grid-cols-4 gap-[2px] p-2">
            {photosData?.map((photoData: TSolutionPhoto, index) => {
              return (
                <PhotoCard
                  id={photoData.id}
                  key={photoData.id}
                  photoUrl={utils.addSizeToFirestackUrl(
                    photoData.photoUrl,
                    800,
                    800
                  )}
                  postUrl={photoData.postUrl}
                  locationName={photoData.locationName}
                  locationAddress={utils.truncateAddress(
                    photoData.locationAddress
                  )}
                  createdAt={dayjs(photoData.createdAt).format(
                    'MMM DD[,] YYYY'
                  )}
                  isFavourite={photoData.isFavourite}
                  isFeatured={photoData.isFeatured}
                  isPublic={photoData.isPublic}
                  fundId={photoData.fundId}
                  solutionId={solutionId}
                  handleImageOnClick={() => handleImageClick(index)}
                  isGridView={gridViewActive}
                />
              )
            })}
          </div>
        </>
      )}
      <div className="my-8 p-4 px-32">
        <Pagination
          currentPage={parseInt(String(pagination.currentPage))}
          meta={pagination.meta}
          onFiltering={pagination.handlePagination}
        />
      </div>
      {galleryImages.images?.length ? (
        <ImagesSlideGallery
          images={galleryImages.images}
          activeImageIndex={galleryImages.activeImageIndex}
          isOpen={galleryImages.isOpen}
          setIsOpen={galleryImages.setIsOpen}
        />
      ) : null}
    </div>
  )
}

export default GalleryPage
