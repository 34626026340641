import { TRoute } from '@types'

import ListContainer from './containers/list.container'
import GalleryContainer from './containers/gallery.container'

const routes: TRoute[] = [
  { element: <ListContainer />, path: '/solutions/:solutionId/applications' },
  {
    element: <GalleryContainer />,
    path: '/solutions/:solutionId/photos',
  },
]

export { routes }
