import React from 'react'
import RootInfrastructure from './infrastructure/root.infrastructure'
import QueryInfrastructure from './infrastructure/query.infrastructure'

const App = () => {
  return (
    <React.StrictMode>
      <QueryInfrastructure>
        <RootInfrastructure />
      </QueryInfrastructure>
    </React.StrictMode>
  )
}

export default App
