import { Badge, Text } from '@changex/design-system'
import React from 'react'
import { TApplication } from '../../types'
import { utils } from '../../utils'
import Step from './step'

type TProps = {
  data?: TApplication | null
}

const ApplicationDetailsSteps: React.FC<TProps> = ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <div>
      <div className="flex justify-between">
        <Text size="xs" variant="light" upper>
          30 days challenge
        </Text>
        <Badge
          variant={utils.getDaysLeftStatus(data?.challengeDaysRemaining) as any}
        >
          {data?.challengeDaysRemaining} days left
        </Badge>
      </div>
      <div className="mt-6 flex gap-8">
        {data?.onboardingSteps?.map((step, index) => (
          <Step
            completed={
              data?.completedOnboardingSteps?.find(
                (st) => st.onboardingStepId === Number(step.id)
              )?.completed
            }
            name={step.stepType}
            position={index + 1}
            key={step.id}
          />
        ))}
      </div>
    </div>
  )
}

export default ApplicationDetailsSteps
