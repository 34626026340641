import { FC, useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useAuth } from '@features/applications/hooks'

interface LaunchdarklyInfrastructureProps {
  children: React.ReactNode | React.ReactNode[]
}

const LaunchdarklyInfrastructure: FC<LaunchdarklyInfrastructureProps> = ({
  children,
}) => {
  const { data: user } = useAuth()
  const ldClient = useLDClient()

  useEffect(() => {
    if (ldClient && user) {
      const { id, email, fullName } = user
      ldClient.identify({
        kind: 'user',
        key: `user-${id}`,
        name: fullName,
        email,
      })
    }
  }, [ldClient, user])
  return <>{children}</>
}

export default LaunchdarklyInfrastructure
