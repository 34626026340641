import styled from 'styled-components'
import { Icon, Progress, Text } from '@changex/design-system'
import dayjs from 'dayjs'
import { TApplication } from '../../../types'
import { utils } from '../../../utils'

type TProps = {
  data: TApplication
}

const ProgressWrapper = styled.div`
  flex: 0 1 325px;
`

const ApplicationProgress: React.FC<TProps> = ({ data }) => {
  const { onboardingSteps, completedOnboardingSteps, challengeDaysRemaining } =
    data

  const steps = [...JSON.parse(JSON.stringify(onboardingSteps || []))]

  steps?.forEach((item, i) => {
    const itemStep = completedOnboardingSteps?.findIndex(
      (step2) => step2.onboardingStepId === Number(item.id)
    )
    if (itemStep !== undefined) {
      steps[i].completed = itemStep > -1
    }
  })

  const isChallengeFinished = utils.isChallengeStepsCompleted(steps)
  const dateFormat = 'MMM D, YYYY'
  const currentStepIndex = steps?.findIndex((step) => step.completed === false)

  return (
    <ProgressWrapper>
      <div className="flex items-center justify-between gap-x-10">
        <Text
          className="flex items-center"
          variant={
            isChallengeFinished
              ? 'success-dark'
              : utils.getDaysLeftStatus(challengeDaysRemaining)
          }
          size="sm"
        >
          {isChallengeFinished ? (
            'Challenge completed'
          ) : challengeDaysRemaining > 0 ? (
            <>
              <Icon className="pr-1.5" icon="Clock" size="sm" />
              {challengeDaysRemaining}{' '}
              {challengeDaysRemaining === 1 ? `day` : `days`} left
            </>
          ) : (
            'Challenge overdue'
          )}
        </Text>
        <div>
          <Text size="sm">
            Applied: {dayjs(data?.submittedAt).format(dateFormat)}
          </Text>
        </div>
      </div>
      <div className="mt-4">
        <Progress steps={steps} currentStage={currentStepIndex + 1} />
      </div>
      <div className="mt-2">
        {currentStepIndex >= 0 ? (
          <Text className="capitalize" size="sm">
            Step: {onboardingSteps[currentStepIndex]?.stepType}
          </Text>
        ) : null}
      </div>
    </ProgressWrapper>
  )
}

export default ApplicationProgress
