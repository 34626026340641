const schema = {
  applications: {
    type: 'solution_applications',
    relationships: {},
    fields: {
      receivedStarterCallAt: 'string',
      teamNotes: 'string',
    },
  },
}

export default schema
