import { useMutation } from 'jsonapi-react'
import toast from 'react-hot-toast'
import { Alert, Button, Text } from '@changex/design-system'
import { useState, useEffect } from 'react'
import { Case, Default, Else, If, Switch, Then, When } from 'react-if'
import TextOverflow from '../text-overflow'
import { TApplication } from '../../types'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import styled from 'styled-components'

type TProps = {
  data?: TApplication | null
  onChangexNotes: (note: string, applicationId: number) => void
  onTeamNotes: (note: string, applicationId: number) => void
}

const editorConfig = {
  toolbar: [
    'bold',
    'italic',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'undo',
    'redo',
  ],
}

const EditorWrapper = styled.div`
  ul,
  ol {
    margin: 0;
    padding-left: 35px;
    list-style-type: initial;
  }
  ol {
    list-style-type: decimal;
  }
`

const ApplicationDetailsNotes: React.FC<TProps> = ({
  data,
  onChangexNotes,
  onTeamNotes,
}) => {
  const [editingTeamNotes, setEditingTeamNotes] = useState<boolean>(false)
  const [teamNotes, setTeamNotes] = useState<string>(data?.teamNotes || '')
  const [teamNotesChanges, setTeamNotesChanges] = useState<string>(
    data?.teamNotes || ''
  )
  const [updateApplicant, { isLoading, data: updatedData }] = useMutation<any>([
    'applications',
    data?.id,
  ])

  const [editingChangexNotes, setEditingChangexNotes] = useState<boolean>(false)
  const [changexNotes, setChangexNotes] = useState<string>(
    data?.changexNotes || ''
  )

  const handleTeamNotes = async () => {
    if (editingTeamNotes) {
      await updateApplicant({ teamNotes: teamNotesChanges })
    }
    setEditingTeamNotes((oldState) => !oldState)
  }

  const handleChangexNotes = () => {
    if (editingChangexNotes) {
      onChangexNotes(changexNotes, Number(data?.id))
    }
    setEditingChangexNotes((oldState) => !oldState)
  }

  useEffect(() => {
    if (updatedData) {
      setTeamNotes(updatedData?.teamNotes)
      toast.success('The applicant has been updated.')
    }
  }, [updatedData])

  return (
    <div className="mt-8">
      <Alert>
        <div>
          <div className="mb-2">
            <Text size="xs" variant="light" upper>
              Social innovator notes
            </Text>
          </div>
          <EditorWrapper className="mb-3">
            <If condition={editingTeamNotes}>
              <Then>
                <CKEditor
                  editor={ClassicEditor}
                  data={teamNotesChanges}
                  config={editorConfig}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setTeamNotesChanges(data)
                  }}
                  data-testid="editor"
                />
              </Then>
              <Else>
                <Text>
                  <TextOverflow>{teamNotes}</TextOverflow>
                </Text>
              </Else>
            </If>
          </EditorWrapper>
          <Button
            className="!py-1.5 !px-2.5"
            weight="tertiary"
            onClick={handleTeamNotes}
            loading={isLoading}
          >
            <Switch>
              <Case condition={teamNotes && !editingTeamNotes}>Edit</Case>
              <Case condition={editingTeamNotes}>Save</Case>
              <Default>Add</Default>
            </Switch>{' '}
            note
          </Button>
          <If condition={editingTeamNotes}>
            <Then>
              <Button
                className="ml-2 !py-1.5 !px-2.5"
                weight="tertiary"
                onClick={() => {
                  setEditingTeamNotes(false)
                }}
              >
                Cancel
              </Button>
            </Then>
          </If>
        </div>
        <div className="mt-10">
          <div className="mb-2 flex items-center justify-between">
            <Text size="xs" variant="light" upper>
              Changex notes
            </Text>
          </div>
          <div className="mb-3">
            <If condition={editingChangexNotes}>
              <Then>
                <textarea
                  className="block h-[200px] w-full resize-none rounded-md border border-gray-300 px-4 py-2 sm:text-sm"
                  onChange={(event) => setChangexNotes(event.target.value)}
                  value={changexNotes}
                />
              </Then>
              <Else>
                <Text>
                  <If condition={changexNotes}>
                    <Then>
                      <TextOverflow>{changexNotes}</TextOverflow>
                    </Then>
                    <Else>No notes yet.</Else>
                  </If>
                </Text>
              </Else>
            </If>
          </div>
          <When condition={false}>
            <Button
              className="!py-1.5 !px-2.5"
              weight="tertiary"
              onClick={handleChangexNotes}
            >
              <Switch>
                <Case condition={changexNotes && !editingChangexNotes}>
                  Edit
                </Case>
                <Case condition={editingChangexNotes}>Save</Case>
                <Default>Add</Default>
              </Switch>{' '}
              note
            </Button>
          </When>
        </div>
      </Alert>
    </div>
  )
}

export default ApplicationDetailsNotes
