import { TStep } from '@features/applications/types'
import { contants } from './constants'

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

const utils = {
  getDaysLeftStatus: (daysRemaining: number) => {
    if (daysRemaining === 0) {
      return 'light'
    }
    if (daysRemaining <= 7) {
      return 'error'
    }
    if (daysRemaining <= 14) {
      return 'warning'
    }
    return 'success-dark'
  },
  getBadgeStatus: (status: string) => {
    const errorStatus: string[] = ['rejected', 'failed']
    const infoStatus: string[] = ['allocated', 'pre_allocated']
    const successStatus: string[] = ['succeeded', 'approved']

    if (errorStatus.includes(status)) {
      return 'error'
    }

    if (infoStatus.includes(status)) {
      return 'info'
    }

    if (successStatus.includes(status)) {
      return 'success'
    }

    return status
  },
  createLinkToFund: (data) => {
    return `${CHANGEX_ADDRESS}/funds/${data?.fund?.slug}`
  },
  createLinkToLocation: (data) => {
    return `${CHANGEX_ADDRESS}/${data?.solution?.slug}/${data?.location?.slug}`
  },
  createLinkToPage: (data) => {
    return `${CHANGEX_ADDRESS}/${data?.solution?.slug}/${data?.location?.slug}`
  },
  getStatusLabel: (status: string) => {
    switch (status) {
      case 'pre_allocated':
        return 'Preallocated'
      case 'allocated':
        return 'Allocated'
      case 'succeeded':
        return 'Succeeded'
      case 'approved':
        return 'Approved'
      case 'paid_seed':
        return 'Paid seed'
      case 'impact':
        return 'Impact'
      case 'paid_impact':
        return 'Paid impact'
      case 'failed':
        return 'Failed'
      case 'failed_impact':
        return 'Failed impact'
      case 'refunded':
        return 'Refunded'
      case 'rejected':
        return 'Rejected'
      case 'unfunded':
        return 'Not funded'
      default:
        return status || '--'
    }
  },

  isChallengeStepsCompleted: (steps: TStep[]) =>
    steps?.filter((step) => step.level === 'challenge' && !step.completed)
      .length === 0,

  normalizeFiltersToJsonApi: (filters, stateFilters) => {
    const { q, status, step, sort, fund, funded, in_challenge } = filters || {}

    const resultObj = { ...stateFilters }
    if (q) {
      resultObj['filter']['keywords[eq]'] = q
    } else {
      if (resultObj['filter'].hasOwnProperty('keywords[eq]')) {
        delete resultObj['filter']['keywords[eq]']
      }
    }
    if (status) {
      resultObj['filter']['status[eq]'] = status
    } else {
      if (resultObj['filter'].hasOwnProperty('status[eq]')) {
        delete resultObj['filter']['status[eq]']
      }
    }
    if (step) {
      resultObj['filter']['incomplete_challenge_onboarding_step'] = step
    } else {
      if (
        resultObj['filter'].hasOwnProperty(
          'incomplete_challenge_onboarding_step'
        )
      ) {
        delete resultObj['filter']['incomplete_challenge_onboarding_step']
      }
    }
    if (fund && fund !== 'fund') {
      resultObj['filter']['fund_id'] = fund
    } else {
      if (resultObj['filter'].hasOwnProperty('fund_id')) {
        delete resultObj['filter']['fund_id']
      }
    }
    if (funded) {
      resultObj['filter']['funded'] = funded
    } else {
      if (resultObj['filter'].hasOwnProperty('funded')) {
        delete resultObj['filter']['funded']
      }
    }
    if (in_challenge) {
      resultObj['filter']['in_challenge'] = in_challenge
    } else {
      if (resultObj['filter'].hasOwnProperty('in_challenge')) {
        delete resultObj['filter']['in_challenge']
      }
    }
    if (sort) {
      resultObj['sort'] = sort
    }
    return resultObj
  },
  parseQueryString: (filtersObj: Record<string, any>): Record<string, any> => {
    const result: Record<string, any> = {}

    Object.entries(filtersObj || {}).forEach(([key, value]) => {
      const keys = key.split(/\[|\]|\.|\]/).filter(Boolean)
      let current = result

      keys.forEach((keyPart, index) => {
        if (index === keys.length - 1) {
          current[keyPart] = value
        } else {
          if (!current[keyPart]) {
            current[keyPart] = {}
          }
          current = current[keyPart]
        }
      })
    })

    return result
  },
  flattenObject: (
    obj: Record<string, any>,
    parentKey = ''
  ): Record<string, any> => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key]
      const newKey = parentKey ? `${parentKey}[${key}]` : key

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(acc, utils.flattenObject(value, newKey))
      } else if (Array.isArray(value)) {
        value.forEach((item: any, index: number) => {
          const arrayKey = `${newKey}[${index}]`
          if (
            typeof item === 'object' &&
            item !== null &&
            !Array.isArray(item)
          ) {
            Object.assign(acc, utils.flattenObject(item, arrayKey))
          } else {
            acc[arrayKey] = item
          }
        })
      } else {
        acc[newKey] = value
      }

      return acc
    }, {})
  },
  addSizeToFirestackUrl(
    photoUrl: string,
    width: number | string = 800,
    height: number | string = 800
  ) {
    const urlComponents = photoUrl.split('/')
    const handle = urlComponents[urlComponents.length - 1]
    return `${urlComponents
      .slice(0, -1)
      .join('/')}/resize=w:${width},h:${height},fit:crop/${handle}`
  },
  hasValue(
    value: string | boolean | number | undefined | null | Record<string, any>
  ): boolean {
    if (value === undefined || value === null) {
      return false
    }

    if (typeof value === 'string' && value.trim() === '') {
      return false
    }

    if (Array.isArray(value)) {
      return value.length > 0
    }

    return true
  },
  removeEmptyFields(obj: object): object {
    const newObj = {}
    for (const key in obj) {
      if (utils.hasValue(obj[key])) {
        if (typeof obj[key] === 'object') {
          const nestedObj = utils.removeEmptyFields(obj[key])
          if (Object.keys(nestedObj).length > 0) {
            newObj[key] = nestedObj
          }
        } else {
          newObj[key] = obj[key]
        }
      }
    }
    return newObj
  },
  truncateAddress(address: string): string {
    const items = address.split(',').map((s) => s.trim())
    return items.slice(-2).join(', ')
  },
  getDefaultGalleryFilter(solutionId: string | undefined) {
    const defaultFilters = { ...contants.defaultGalleryFilters }
    if (solutionId) {
      defaultFilters.filter.solution_id = solutionId
    }
    return defaultFilters
  },
}

export { utils }
