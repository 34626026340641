import { Badge, Text } from '@changex/design-system'
import React from 'react'
import { If, Then } from 'react-if'
import { TApplication } from '../../types'
import { utils } from '../../utils'

type TProps = {
  data?: TApplication | null
}

const ApplicationDetailsInfo: React.FC<TProps> = ({ data }) => {
  const status = data?.applicationStatus || 'n/a'
  const fundName = data?.fund?.name
  return (
    <div className="mt-3.5 flex justify-between gap-8">
      <div className="flex flex-col">
        <Text size="xs" variant="light" upper>
          Status
        </Text>
        <div className="mt-3 flex items-center gap-x-1">
          <Badge variant={utils.getBadgeStatus(status)}>
            {utils.getStatusLabel(status)}
          </Badge>
        </div>
      </div>
      <If condition={fundName}>
        <Then>
          <div className="flex flex-1 flex-col">
            <Text size="xs" variant="light" upper>
              Fund
            </Text>
            <Text className="mt-3" size="sm">
              <a
                href={utils.createLinkToFund(data)}
                rel="noreferrer"
                target="_blank"
                className="hover:underline"
              >
                {data?.fund?.name}
              </a>
            </Text>
          </div>
        </Then>
      </If>
      <div className="flex flex-1 flex-col">
        <Text size="xs" variant="light" upper>
          Location
        </Text>
        <Text className="mt-3" size="sm">
          {data?.location.address}
        </Text>
      </div>
      <div className="flex flex-1 flex-col">
        <Text size="xs" variant="light" upper>
          Project
        </Text>
        <Text className="mt-3" size="sm">
          <a
            href={utils.createLinkToPage(data)}
            rel="noreferrer"
            target="_blank"
            className="hover:underline"
          >
            {data?.location.name}
          </a>
        </Text>
      </div>
    </div>
  )
}

export default ApplicationDetailsInfo
