import classNames from 'classnames'
import { When } from 'react-if'
import { Divider, Text } from '@changex/design-system'
import { TFilter, TSolution } from '../../types'

type TProps = {
  data?: TSolution['aggregates']
  expanded: boolean
  onFiltering: (filters: TFilter) => void
}

const ApplicationsSummaryOthers: React.FC<TProps> = ({
  data,
  expanded,
  onFiltering,
}) => {
  if (!expanded) {
    return null
  }

  return (
    <div
      className={classNames({
        'border-r border-gray-200 px-6 py-5': expanded,
      })}
    >
      <div>
        <Text className="mb-2.5" size="xs" upper variant="light">
          Others
        </Text>
      </div>
      <div className={classNames('flex', { 'gap-x-8': expanded })}>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-failed"
          onClick={() => {
            onFiltering({
              status: 'failed',
            })
          }}
        >
          <Text size="2xl" weight="medium">
            {data?.failed || 0}
          </Text>
          <Text size="xs" variant="light">
            Failed
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-rejected"
          onClick={() => {
            onFiltering({
              status: 'rejected',
            })
          }}
        >
          <Text size="2xl" weight="medium">
            {data?.rejected || 0}
          </Text>
          <Text size="xs" variant="light">
            Rejected
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-refunded"
          onClick={() => {
            onFiltering({
              status: 'refunded',
            })
          }}
        >
          <Text size="2xl" weight="medium">
            {data?.refunded || 0}
          </Text>
          <Text size="xs" variant="light">
            Refunded
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-unfunded"
          onClick={() => {
            onFiltering({
              status: 'unfunded',
            })
          }}
        >
          <Text size="2xl" weight="medium">
            {data?.unfunded || 0}
          </Text>
          <Text className="whitespace-nowrap" size="xs" variant="light">
            Not funded
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
      </div>
    </div>
  )
}

export default ApplicationsSummaryOthers
