import { Icon, Tooltip } from '@changex/design-system'

const GalleryLegend = () => {
  return (
    <div className="z-30">
      <Tooltip placement="left-start">
        <Tooltip.Trigger>
          <div
            className="flex gap-2 border-0 px-0 text-base font-light font-normal text-gray-700"
            data-testid="button"
          >
            Help
            <Icon
              icon="QuestionMarkCircle"
              size="sm"
              className="text-gray-400"
            />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <div className="flex w-64 flex-col gap-3">
            <div>
              Click on the buttons above to switch your view between photo only
              and details.
            </div>
            <div>
              See photos posted by applicants to certain funds and/or based on
              their current project status by using the filters.
            </div>
            <div>
              Feature a photo on your idea page by clicking the star icon on
              photos.
            </div>
            <div>
              Favourite photos to curate the best photos in one view by clicking
              the heart icon on photos.
            </div>
          </div>
        </Tooltip.Content>
      </Tooltip>
    </div>
  )
}

export default GalleryLegend
