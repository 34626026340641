import { Heading, Skeleton } from '@changex/design-system'
import { useSolutionById } from 'features/applications/hooks'
import { useParams } from 'react-router-dom'
import { AppLayout } from '@layouts'
import { Toaster } from 'react-hot-toast'
import { InformationCircleIcon, EmojiSadIcon } from '@heroicons/react/outline'
import Funds from 'features/payments/components/funds/funds'

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-col divide-y divide-gray-300">
      <Skeleton h="50px" />
      <div className="flex flex-col divide-y divide-gray-200">
        {[...Array(5)].map((_, index) => (
          <LoadingRow key={index} />
        ))}
      </div>
    </div>
  )
}

const LoadingRow = () => {
  return (
    <div className="grid grid-cols-8 gap-6">
      <Skeleton className="col-span-1 my-5" />
      <Skeleton className="col-span-5 my-5" />
      <Skeleton className="col-span-2 my-5" />
    </div>
  )
}

const EmptyState = () => {
  return (
    <div className="mt-32 flex flex-col items-center">
      <InformationCircleIcon className="h-12 w-12 text-gray-400" />
      <p className="mt-2 text-lg font-semibold text-gray-900">No funds</p>
      <p className="mt-1 text-gray-700 opacity-70">
        Your idea has not been added to any funds yet
      </p>
    </div>
  )
}

const ErrorState = () => {
  return (
    <div className="mt-32 flex flex-col items-center">
      <EmojiSadIcon className="h-12 w-12 text-gray-400" />
      <p className="mt-2 text-lg font-semibold text-gray-900">
        Something went wrong
      </p>
      <p className="mt-1 text-gray-700 opacity-70">
        There was an error while trying to fetch the funds
      </p>
    </div>
  )
}

const FundsPageContent = () => {
  const { solutionId } = useParams()
  const { data: solution, isLoading } = useSolutionById(solutionId!)

  if (isLoading) {
    return <LoadingSkeleton />
  }

  if (!solution || !solutionId) {
    return <ErrorState />
  }

  if (!solution.funds || solution.funds.length === 0) {
    return <EmptyState />
  }

  return (
    <table className="w-full divide-y divide-gray-300">
      <thead>
        <tr className="border-t border-gray-100 bg-gray-50">
          <th className="px-2 py-4 pl-4 text-left text-sm font-medium 2xl:text-base">
            Status
          </th>
          <th className="px-2 py-4 text-left text-sm font-medium 2xl:text-base">
            Start date
          </th>
          <th className="px-2 py-4 text-left text-sm font-medium 2xl:text-base">
            Name
          </th>
          <th className="px-2 py-4 text-left text-sm font-medium 2xl:text-base">
            Currency
          </th>
          <th className="px-2 py-4 text-right text-sm font-medium 2xl:text-base">
            Total
          </th>
          <th className="px-2 py-4 text-right text-sm font-medium 2xl:text-base">
            Remaining
          </th>
          <th className="px-2 py-4 text-right text-sm font-medium 2xl:text-base">
            Payment
          </th>
          <th className="px-2 py-4 text-right text-sm font-medium 2xl:text-base">
            In fund
          </th>
          <th className="px-2 py-4 text-right text-sm font-medium 2xl:text-base">
            Limit
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        <Funds
          funds={solution.funds}
          solutionSlug={solution.slug}
          solutionId={solutionId}
        />
      </tbody>
    </table>
  )
}

const FundsPage = () => {
  return (
    <AppLayout>
      <div>
        <div className="mb-8 flex justify-between">
          <Heading>Funds</Heading>
        </div>
        <FundsPageContent />
      </div>
      <Toaster />
    </AppLayout>
  )
}

export default FundsPage
