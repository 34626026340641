const contants = {
  defaultSteps: [
    'video',
    'call',
    'guide',
    'meeting',
    'invite',
    'event',
    'post',
    'impact',
    'survey',
  ],
  status: [
    { id: '', label: 'Status' },
    { id: 'pre_allocated', label: 'Preallocated' },
    { id: 'allocated', label: 'Allocated' },
    { id: 'succeeded', label: 'Succeeded' },
    { id: 'approved', label: 'Approved' },
    { id: 'paid_seed', label: 'Paid seed' },
    { id: 'impact', label: 'Impact' },
    { id: 'paid_impact', label: 'Paid impact', divider: true },
    { id: 'failed', label: 'Failed' },
    { id: 'failed_impact', label: 'Failed impact' },
    { id: 'rejected', label: 'Rejected' },
    { id: 'refunded', label: 'Refunded' },
    { id: 'unfunded', label: 'Not funded' },
  ],
  steps: [
    { id: '', label: 'Current challenge step' },
    { id: 'video', label: '1 Video' },
    { id: 'call', label: '2 Call' },
    { id: 'guide', label: '3 Guide' },
    { id: 'meeting', label: '4 Meeting' },
    { id: 'invite', label: '5 Invite' },
    { id: 'event', label: '6 Event' },
    { id: 'post', label: '7 Post' },
    { id: 'impact', label: '8 Impact' },
    { id: 'survey', label: '9 Survey' },
  ],
  sorts: [
    { id: 'date_applied', label: 'Date applied' },
    { id: 'recently_updated', label: 'Recently updated' },
    {
      id: 'challenge_days_remaining',
      label: 'Challenge days remaining',
    },
  ],
  funds: [{ id: '', label: 'Fund' }],
  defaultFilters: {
    filter: {
      'stage[neq]': 'draft',
      'type[eq]': 'solution_applications',
    },
    page: {
      size: 20,
    },
    sort: 'date_created',
    stats: {
      total: 'count',
    },
    include: [
      'onboarding_steps',
      'fund',
      'solution',
      'location',
      'owner',
      'completed_onboarding_steps',
    ],
  },
  defaultGalleryFilters: {
    filter: {
      solution_id: '0',
    },
    page: { number: '1' },
    stats: { total: 'count' },
    sort: '-created_at',
  },
  stateCategories: [
    {
      category: 'Funded',
      options: [
        { id: 'paid_seed', label: 'Paid seed' },
        { id: 'impact', label: 'Impact' },
        { id: 'paid_impact', label: 'Paid impact' },
      ],
    },
    {
      category: 'Other',
      options: [
        { id: 'pre_allocated', label: 'Pre allocated' },
        { id: 'allocated', label: 'Allocated' },
        { id: 'succeeded', label: 'Succeeded' },
        { id: 'approved', label: 'Approved' },
        { id: 'failed', label: 'Failed' },
        { id: 'failed_impact', label: 'Failed impact' },
        { id: 'refunded', label: 'Refunded' },
        { id: 'rejected', label: 'Rejected' },
      ],
    },
  ],
}

export { contants }
