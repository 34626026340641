import { TRoute } from '@types'

import DashboardPage from 'app/solutions/[solutionId]/impact/dashboard/page'

const routes: TRoute[] = [
  {
    element: <DashboardPage />,
    path: '/solutions/:solutionId/impact/dashboard',
  },
]

export { routes }
